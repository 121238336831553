import React, {useContext} from 'react';
import { ContextContainer } from '../App';


const ProfilePic = (props) => {
    const stateContextObj = useContext(ContextContainer);
    const image_url = stateContextObj.authObj.picture.data.url;
    const fullname = stateContextObj.authObj.name;
    const email = stateContextObj.authObj.email;
    // console.log("=======OAUTH=========", stateContextObj, stateContextObj.authObj.picture.data.url);
    
    
    return (
        <div>
            <img src={image_url} alt={fullname} />
            <div>{fullname}</div>
            <div>{email}</div>
            </div>
    )
    
}
export default ProfilePic;