import React, { useContext } from 'react';
import { Chip } from '@material-ui/core';
import DirectionsBusOutlinedIcon from '@material-ui/icons/DirectionsBusOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { ContextContainer } from '../App';

const useStyles = makeStyles((theme) => ({
	chipcolor: {
		boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
		// boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
		// padding: '10px',
		margin: '3px',
		background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)'
		// background: 'linear-gradient(45deg, #649173 30%, #DBD5A4 90%)'
		// background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)' #The Stain
	},
	chipcolor1: {
		boxShadow: '0 3px 5px 2px #6491734d',
		margin: '3px',
		background: 'linear-gradient(45deg, #649173 30%, #DBD5A4 90%)'
	},
	chipcolor2: {
		// The Stain
		boxShadow: '0 3px 5px 2px #8700004d',
		margin: '3px',
		background: 'linear-gradient(45deg, #870000 30%, #190A05 90%)'
	},
	chipcolor3: {
		// The reef
		boxShadow: '0 3px 5px 2px #00d2ff4d',
		margin: '3px',
		background: 'linear-gradient(45deg, #00d2ff 30%, #3a7bd5 90%)'
	},
	chipcolor4: {
		// Pizelex
		boxShadow: '0 3px 5px 2px #1143574d',
		margin: '3px',
		background: 'linear-gradient(45deg, #114357 30%, #F29492 90%)'
	},
	chipcolor5: {
		// Moonlit Asteroid
		boxShadow: '0 3px 5px 2px #0F20274d',
		margin: '3px',
		background: 'linear-gradient(45deg, #0F2027 30%, #2C5364 90%)'
	},
	icon: {
		// marginLeft: '10px'
	}
}));

const handleBusDelete = (busCodeToDelete,stateContextObj) => {
    stateContextObj.dispatchToBusCode({type: 'DELETE_BUS', value: busCodeToDelete});
};

const BusSummary = (props) => {
	const classes = useStyles();
	const stateContextObj = useContext(ContextContainer);

	const buscodes = stateContextObj.buslist;

	const filteredBusCodes = buscodes.filter((i) => {
		return i.status === props.status ? i : null;
    });
    
    const previewBusList = filteredBusCodes.slice(-3);

	const busRender = previewBusList.map((i, k) => {
		const chipStyle = props.status === true ? classes.chipcolor3 : classes.chipcolor;
		return (
			<Chip
				color="primary"
				label={i.code}
				key={k}
				size="small"
				onDelete={()=>{
                    handleBusDelete(i.code,stateContextObj);
                }}
				className={chipStyle}
				icon={<DirectionsBusOutlinedIcon className={classes.icon} />}
			/>
		);
	});
	return <div>{busRender}</div>;
};

export default BusSummary;
