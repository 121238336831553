import React , { useContext } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { ContextContainer } from '../App';

import FacebookAuth from 'react-facebook-auth';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                ValueMedia Advertising Ltd.
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const MyFacebookButton = ({ onClick }) => {
    const classes = useStyles();
    return (
        <div>
            <Button
                // type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={onClick}
            >
                Sign In with your Facebook Account</Button>
        </div>
    )
};


// const authenticate = (response) => {
//     if (response.accessToken && !localStorage.getItem('access_token')){
//         localStorage.setItem('access_token', response.accessToken);
//         localStorage.setItem('authObj', JSON.stringify(response));

//         console.log("CHECK:", response);
//     }else{
//         console.log("Already logged in");
//     }

    
// };

export default function SignIn() {
    const classes = useStyles();
    const stateContextObj = useContext(ContextContainer);

    
    // console.log("Output::", stateContextObj);

    // useEffect(() => {
    //     if (localStorage.getItem('access_token')) {
    //         stateContextObj.dispatchToBusCode({ type: 'AUTH_USER' });
    //         // console.log(stateContextObj);
    //     }
    // })
    
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Facebook - Sign in
        </Typography>

                    <FacebookAuth
                        appId="1091666264336909"
                        callback={
                            (response) => {
                                console.log("AUTH", localStorage,localStorage.getItem('access_token'));
                                if(response.status === undefined){
                                    // console.log("+++> 1");
                                }
                                if(response.status === "unknown"){
                                    // console.log("+++> 2");
                                }
                                if (response.accessToken){
                                    // console.log("+++> 3");
                                }
                                if (response.accessToken === undefined){
                                    // console.log("+++> 4");
                                }
                                if (localStorage.getItem('access_token')){
                                    // console.log("+++> 5");
                                }
                                if (localStorage.getItem('access_token') === undefined){
                                    // console.log("+++> 6");
                                }
                                if (localStorage.getItem('access_token') === null){
                                    // console.log("+++> 7");
                                }
                                // Success: status == undefined || response.access_token
                                //Failure: status == "unknown" || response.access_token == undefined 

                            // SUCCESS
                        if (response.status === undefined || response.accessToken || localStorage.getItem('access_token')){
                            stateContextObj.dispatchToBusCode({type: 'AUTHORIZE_USER', value: response});
                            localStorage.setItem('access_token', response.accessToken);
                            localStorage.setItem('authObj', JSON.stringify(response));
                            
                            // console.log("Already logged in", response, stateContextObj.isAuth,stateContextObj);
                            // console.log("CHECK:", response);
                        } else {
                            // FAILURE
                            // if ( response.status == "unknown" || response.accessToken === undefined || localStorage.getItem('access_token') == null )
                            

                            // stateContextObj.isAuth = true;
                        }


                    }
                }
                        component={MyFacebookButton}
                    />


            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}