import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ContextContainer } from '../App';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  centered: {
    display: 'flex',
    justifyContent: 'center'
  },
  subOptionsHeader: {
    marginTop: '20px'
  }

})
);

export default function DialogSelect(props) {
  const classes = useStyles();
  // console.log(props);
  const stateContextObj = useContext(ContextContainer);

  const menuItems = props.locations.map((item) => <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>);
  const [open, setOpen] = React.useState(true);
  // const [age, setAge] = React.useState('');
  // console.log("hi",open,setOpen(true),stateContextObj);
  
  // console.log(props.positionOptions);
  const sub_options = props.positionOptions.map((item) => <FormControlLabel value={item.id} key={item.id} control={<Radio />} label={item.name} />);

  const sub_options_header = props.positionOptions.length ? <div className = { classes.subOptionsHeader}>Direction: </div> : <div></div>;
  const handleChange = event => {
    // setAge(Number(event.target.value) || '');
    stateContextObj.dispatchToBusCode({ type: 'SELECT_LOCATION', value: event.target.value, directionOptions: props.direction_map.getDirectionAndPosition(event.target.value, props.direction_map) });

    

    // console.log(event.target.value, stateContextObj);

  };

  const handleSubOptions = (event) => {
    stateContextObj.dispatchToBusCode({ type: 'SELECT_SUB_LOCATION', value: event.target.value });
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSubmit = (event) => {
    stateContextObj.selectedSubOption && stateContextObj.selectedLocation ? setOpen(false): alert("Select a Location and Direction!");
    
    // 
  };
  const handleClose = (event) => {
    setOpen(false);
    // 
  };

  const showButtonText = () => {

    if(stateContextObj.selectedSubOption && stateContextObj.selectedLocation){
      const sublocation = stateContextObj.directionOptions.filter((element) => element.id === Number(stateContextObj.selectedSubOption));
      const location = stateContextObj.locations.filter((element) => element.id === stateContextObj.selectedLocation);
      return 'LOCATION: ' + location[0].name + ' (' + sublocation[0].name + ')';
    }else{
      return 'Choose your location';
    }
  }
  
  const buttonText = showButtonText();
  return (
    <div className={classes.centered}>
      <Button onClick={handleClickOpen} variant="outlined">{ buttonText }</Button>
      <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle>Select your location</DialogTitle>
        <DialogContent>
          <form className={classes.container}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-dialog-select-label">Bus Stop</InputLabel>
              <Select
                labelId="dialog-select-label"
                id="dialog-select"
                value={stateContextObj.selectedLocation}
                onChange={handleChange}
                input={<Input />}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {menuItems}
              </Select>

              {sub_options_header}
              <RadioGroup aria-label="direction" name="direction" value={Number(props.selectedSubOption)} onChange={handleSubOptions}>
                {sub_options}
              </RadioGroup>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
