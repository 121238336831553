import React, { useContext } from 'react';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ContextContainer } from '../App';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1
		// margin: 20
	},
	button: {
		width: '100%'
	},
	buttonsholder: {
		justifyContent: 'center'
	},
	buttonitemholder: {
		width: '33%',
		maxWidth: '33%',
		flexBasis: '33%'
	}
}));

const Keypad = () => {
	const classes = useStyles();

	const stateContextObj = useContext(ContextContainer);

	const buttonNumbers = stateContextObj.keypadButtons;
	const buttonArray = buttonNumbers.map((element, k) => {
		// console.log(element,k)

		return (
			<Grid item lg={1} key={k} className={classes.buttonitemholder}>
					<Button
						variant="contained"
						color="primary"
						className={classes.button}
						onClick={() => {
							// handleClick(element, e);
							stateContextObj.dispatchToBusCode({ type: 'KEYPRESS', value: element });
							// console.log(element,stateContextObj);
						}}
					>
						{element} 
					</Button>
				</Grid>
		);
	});

	return (
		<div className={classes.root}>
			<Grid container spacing={3} className={classes.buttonsholder}>
				{buttonArray}
			</Grid>
		</div>
	);
};

// const handleClick = (key, e) => {
// 	console.log(key);
// };

export default Keypad;
