export default function reducer(state, action) {
	switch (action.type) {
		case 'KEYPRESS':
			if (action.value >= 0 && action.value <= 9) {
				if (state.currentCode.length >= 3) {
					const newState = { ...state, currentCode: action.value };
					return newState;
				} else {
					const newCurrentCode = state.currentCode + action.value;
					const newState = { ...state, currentCode: newCurrentCode };
					return newState;
				}
			} else if (action.value === '<' && state.currentCode.length >= 1) {
				const newCurrentCode = state.currentCode.substring(0, state.currentCode.length - 1);
				const newState = { ...state, currentCode: newCurrentCode };
				return newState;
			} else if (action.value === 'CLR') {
				const newCurrentCode = '';
				const newState = { ...state, currentCode: newCurrentCode };
				return newState;
			} else {
				const newCurrentCode = '';
				const newState = { ...state, currentCode: newCurrentCode };
				return newState;
			}
		case 'SELECTED_SCREEN_WORKING':
			if (state.currentCode.length === 3) {
				// CHECK FOR DUPLICATES / EXISTING ITEMS
				let duplicateExists = checkBusDuplicates(state.currentCode, state.buslist);

				if (duplicateExists === false) {
					const newBusList = [...state.buslist, { code: state.currentCode, status: true }];
					const newState = { ...state, buslist: newBusList };
					return newState;
				}
			}
			break;

		case 'SELECTED_SCREEN_ISSUES':
			if (state.currentCode.length === 3) {
				// CHECK FOR DUPLICATES / EXISTING ITEMS
				let duplicateExists = checkBusDuplicates(state.currentCode, state.buslist);

				if (duplicateExists === false) {
					console.log(2);
					const newBusList = [...state.buslist, { code: state.currentCode, status: false }];
					const newState = { ...state, buslist: newBusList };
					return newState;
				}
			}
			break;

		case 'DELETE_BUS':
			const newBusList = state.buslist.filter((element) => {
				return element.code !== action.value ? element : null;
			});
			const newState = { ...state, buslist: newBusList };
			return newState;

		case 'AUTHORIZE_USER':
			console.log('LOGGED IN');
			return { ...state, isauth: true, authObj: action.value };
		case 'SELECT_LOCATION':
			// console.log('LOCATION: ');
			// console.log(state);
			return { ...state, selectedLocation: action.value, directionOptions: action.directionOptions };
		case 'SELECT_SUB_LOCATION':
			// console.log('LOCATION: ');
			// console.log(state);
			return { ...state, selectedDirection: action.value };

		default:
			return state;
	}
	return state;
}

const checkBusDuplicates = (needle, haystack) => {
	let duplicateExists = false;
	haystack.forEach((element) => {
		if (needle === element.code) {
			duplicateExists = true;
		}
	});
	return duplicateExists;
};
