import React, { useContext } from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ContextContainer } from '../App';

const useStyles = makeStyles((theme) => ({
	textField: {
		fontSize: '25px',
		textAlign: 'center',
		color: 'red'
	}
}));

const InputDisplay = () => {
	const classes = useStyles();
	const stateContextObj = useContext(ContextContainer);
	const textvalue = stateContextObj.currentBusCode;
	return (
		<TextField
			id="outlined-read-only-input"
			label="Bus Code"
			value={textvalue}
			className={classes.textField}
			margin="normal"
			InputProps={{
				readOnly: true
			}}
			variant="outlined"
		/>
	);
};

export default InputDisplay;
