import React, { useContext } from 'react';
import { Button, Badge } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';
import IssuesIcon from '@material-ui/icons/ReportProblem';
import { ContextContainer } from '../App';

const useStyles = makeStyles((theme) => ({
	flexcontainer: {
		display: 'flex',
		justifyContent: 'space-around'
	},
	margin: {
		margin: theme.spacing(2)
	}
}));

const StatusSelector = () => {
	const classes = useStyles();
	const stateContextObj = useContext(ContextContainer);
	// const [workingscreens, issuescreens] = () => {
	//     console.log("r")
	//     return {1,2};
	// }
	// console.log('STATUS', stateContextObj.currentBusCode);
    const {workingscreens, issuescreens} = calculateBusStatus(stateContextObj.buslist);
    // console.log(busStatus)
	// let workingscreens = 0;
	// let issuescreens = 0;
	// console.log(stateContextObj.buslist.reduce((prev, cur)=>{
	//     cur.status === false ? issuescreens++ : workingscreens++;
	//     return [workingscreens, issuescreens];
	// }));

	return (
		<div className={classes.flexcontainer}>
			<Badge color="primary" badgeContent={workingscreens} className={classes.margin}>
				<Button
					variant="outlined"
					color="primary"
					startIcon={<DoneIcon />}
					onClick={() => {
						stateContextObj.dispatchToBusCode({ type: 'SELECTED_SCREEN_WORKING' });
					}}
				>
					Working
				</Button>
			</Badge>
			<Badge color="primary" badgeContent={issuescreens} className={classes.margin}>
				<Button
					variant="outlined"
					color="secondary"
					startIcon={<IssuesIcon />}
					onClick={() => {
						stateContextObj.dispatchToBusCode({ type: 'SELECTED_SCREEN_ISSUES' });
					}}
				>
					Issues
				</Button>
			</Badge>
		</div>
	);
};

const calculateBusStatus = (haystack) => {
	let workingscreens = 0;
    let issuescreens = 0;
    
    haystack.forEach(element => {
        element.status === false ? issuescreens++ : workingscreens++;
    });
    return { workingscreens, issuescreens };
};

export default StatusSelector;
