import React, { useReducer, createContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Container, Grid, Paper, Typography } from '@material-ui/core';

import Keypad from './components/keypad';
import InputDisplay from './components/display';
import BusSummary from './components/summary';
import Location from './components/location';
import initialState from './data/store';
import StatusSelector from './components/statusselector';
import SignIn from './components/loginpage';
import ProfilePic from './components/profilepic';
import reducer from './reducers/reducer';

import './App.css';




const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		overflow: 'hidden',
		padding: theme.spacing(0, 3)
	},
	paper: {
		maxWidth: 600,
		margin: `${theme.spacing(1)}px auto`,
		padding: theme.spacing(2)
	},
	divider: {
		margin: '20px auto',
		background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)'
	},
	signin: {
		position: 'fixed',
		backgroundColor: 'white',
		zIndex: 2000,
		width: '100vw',
		height: '100vh',
		display: 'block',

	},
	signedin: {
		display: 'none',
		position: 'fixed',
		backgroundColor: 'white',
		zIndex: 100,
		width: '100vw',
		height: '100vh',
	}
}));



export const ContextContainer = createContext();

export default function App() {
	const classes = useStyles();



	const [store, dispatch] = useReducer(reducer, initialState);
	let signinstatus;
	useEffect(() => {
		// console.log("Rerender",store);
	})
	signinstatus = store.isauth ? classes.signedin : classes.signin;
	// console.log("signinstatus", signinstatus)
	// console.log(store,signinstatus);
	// let signintemp = ()=>{
	// 	if (!store.isauth){
	// 		return (<SignIn />)
	// 	}
	// };

	let signintemp = (<div className={signinstatus}><SignIn /></div>);

	

	return (
		<ContextContainer.Provider
			value={{
				keypadButtons: store.buttonKeys,
				currentBusCode: store.currentCode,
				buslist: store.buslist,
				dispatchToBusCode: dispatch,
				isAuth: store.isauth,
				authObj: store.authObj,
				locations: store.locations,
				selectedLocation: store.selectedLocation,
				direction_map: store.direction_map,
				directionOptions: store.directionOptions,
				selectedSubOption: store.selectedDirection,
			}}
		>
			
				{
					// signintemp
				}

			<Container maxWidth="sm" className="containerBody">
				<div className={classes.root}>
					<Paper className={classes.paper}>
						<Grid container wrap="nowrap" spacing={2}>
							<Grid item xs zeroMinWidth>
								<ProfilePic />
							</Grid>
						</Grid>
					</Paper>
					<Paper className={classes.paper}>
						<Grid container wrap="nowrap" spacing={2}>
							<Grid item xs zeroMinWidth>
								<Typography>Working Screens:</Typography>
								<BusSummary status={true} />
							</Grid>
						</Grid>
					</Paper>
					<Paper className={classes.paper}>
						<Grid container wrap="nowrap" spacing={2}>
							<Grid item xs zeroMinWidth>
								<Typography>Bad Screens:</Typography>
								<BusSummary status={false} />
							</Grid>
						</Grid>
					</Paper>

					<Paper className={classes.paper}>
						<Grid container wrap="nowrap" spacing={2}>
							<Grid item xs>
								<InputDisplay />
								<StatusSelector />
								<Divider className={classes.divider} />
								<Keypad />
							</Grid>
						</Grid>
					</Paper>

					<Paper className={classes.paper}>
						<Grid container wrap="nowrap" spacing={2}>
							<Grid item xs>
								<Location locations={store.locations} direction_map={store.direction_map} positionOptions={store.directionOptions} selectedSubOption={store.selectedDirection} />
							</Grid>
						</Grid>
					</Paper>
				</div>
				
			</Container>
		</ContextContainer.Provider>
	);
}

ReactDOM.render(<App />, document.querySelector('#root'));
