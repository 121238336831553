const initialState = {
	buttonKeys: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '<', 'CLR'],
	currentCode: '',
	buslist: [
		{ code: '123', status: true },
		{ code: '454', status: false }
		// {code: "323", status: true},
		// {code: "006", status: true},
		// {code: "220", status: false},
		// {code: "100", status: false}
	],
	location: {
		working: 0,
		issues: 0
	},
	isauth: false,
	authObj: { picture: { data: { url: 'a' } } },
	locations: [
		{id: 1, name: 'IKORODU'},
		{id: 2, name: 'ARUNA'},
		{id: 3, name: 'AGRIC'},
		{id: 4, name: 'IDIROKO'},
		{id: 5, name: 'OGOLONTO'},
		{id: 6, name: 'MAGIDUN'},
		{id: 7, name: 'AJEGUNLE'},
		{id: 8, name: 'IDERA'},
		{id: 9, name: 'OWODE ONIRI'},
		{id: 10, name: 'MILE 12'},
		{id: 11, name: 'KETU'},
		{id: 12, name: 'OJOTA'},
		{id: 13, name: 'NEW GARAGE'},
		{id: 14, name: 'MARYLAND'},
		{id: 15, name: 'OBANIKORO'},
		{id: 16, name: 'IDIROKO'},
		{id: 17, name: 'PALMGROOVE'},
		{id: 18, name: 'ONIPAN'},
		{id: 19, name: 'FADEYI'},
		{id: 20, name: 'BARRCKS'},
		{id: 21, name: 'YABA'},
		{id: 22, name: 'STADIUM'},
		{id: 23, name: 'ALAGOMEJI'},
		{id: 24, name: 'ADEKUNLE'},
		{id: 25, name: 'POST OFFICE'},
		{id: 26, name: 'OYINGBO'},
		{id: 27, name: 'COSTAIN'},
		{id: 28, name: 'IDDO'},
		{id: 29, name: 'LEVENTIS'},
		{id: 30, name: 'C.M.S'},
		{id: 31, name: 'T.B.S'},
	],
	selectedLocation: '',
	direction_map: {
		option1: { id: 1, name: 'NORTH' },
		option2: { id: 2, name: 'SOUTH' },
		option1a: { id: 3, name: 'NORTH 1' },
		option1b: { id: 4, name: 'NORTH 2' },
		default: ['option1', 'option2'],
		map: [
			{ ids: [1], position:['option1']},
			{ ids: [27], position: ['option1a', 'option1b']},
			{ ids: [21,23,24,25,29,30,31], position: ['option2']},
		],
		getDirectionAndPosition: (currentLocationObj, direction_map) => {
			let obj = null;
			direction_map.map.forEach(element => {
				if (element.ids.includes(currentLocationObj)) {
					obj = element.position.map((e) => direction_map[e]);
				}
			});

			if (!obj) {
				// DEFAULT CONDITION
				obj = direction_map.default.map((e) => direction_map[e]);
			}

			return obj;
		}

},
directionOptions: [],
selectedDirection: 0,

};




// getDirectionAndPosition(initialState.locations[5], initialState.direction_map)
// getDirectionAndPosition(initialState.locations[0], initialState.direction_map)

export default initialState;

